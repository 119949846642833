body {
    background-color: $bgcolor;
    color: $white;
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
    @include backgroundImage('/images/bg-blue.webp');
    background-attachment: fixed;
    background-size: 100vw;
    background-repeat: repeat-y;
    background-position: center;

    @include media-phone-only() {
        background-attachment: scroll;
    }
}

* {
    font-family: 'Oswald', sans-serif;
}


.section {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    .wrapper {
        width: 1074px;
        max-width: calc(100% - 60px);
        margin: 0px auto;
        display: block;
    }

    .section-title {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $red;
        color: $highlight;
        text-transform: uppercase;
        font-size: 36px;
        font-weight: bold;        
        text-shadow: 1px 1px 0px $black, 2px 2px 0px $black, 3px 3px 0px $black;

    }
}

