.section.hero {
    position: relative;
    z-index: 1;
    @include backgroundImage('images/hero-blur.webp');
    background-size: cover;
    background-position: center;


    &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: rgba($red, 0.5);
        content: " ";
        display: block;
    }

    .wrapper {
        width: 100%;
        max-width: 1920px;
        max-height: calc(100vh -  100px);
        overflow: hidden;
        position: relative;
        background-color: $black;

        @include media-tablet-down {
            border-bottom: 20px solid $red;
        }



        &:before {
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 10px;
            background: rgba($red, 1);
            content: " ";
            display: none;
            @include media-tablet-down {
                display: block;
            }

        }


        
        video, .hero-image {
            width: 100%;
            height: auto;
            margin-bottom: -1px;

            @include media-phone-only() {
                height: 50vh;
                width: auto;
                transform: translateX(-18%);
            }
        
        }

        .content{
            position: absolute;
            right: 20px;
            bottom: 11%;
            width: calc(35% - 20px);
            @include media-phone-only() {
                width: calc(50% - 20px);
                right: 20px;
                bottom: 10px;
            }

            .logo-image {
                height: 180px;
                width: auto;
                margin-left: -110px;
                @include media-desktop-down() {
                    height: 10vw;
                    margin-left: -6vw;
                }
                @include media-tablet-down() {
                    display: none;
                }

            }

            p {
                color: $white;
                font-size: 21px;
                margin-bottom: 1em;
                margin-top: 1em;
                text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;
                @include media-desktop-down() {
                    font-size: 15px;
                }
                @include media-phone-only() {
                    font-size: 10px;
                    text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;
                    margin-left: -5vw;
                }
            }

            h4 {
                color: $highlight;
                font-size: 21px;
                text-transform: uppercase;
                text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;

                @include media-phone-only() {
                    font-size: 15px;
                    margin-left: -5vw;
                }
            }
        }
    }
}