@keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }

.carousel-object.carousel-secondary {
    @include backgroundImage('images/bg-red-circles.webp');
    display: flex;
    width: 100%;
    align-items: center;    
    justify-content: center;
    background-attachment: fixed;
    background-position: center top;
    background-size: cover;
    flex-wrap: wrap;
    position: relative;

    @include media-phone-only() {
        height: 61vw;
        background-attachment: scroll;
        background-size: 400%;
        background-position: center 70%;
    }


    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .lds-ripple div {
        position: absolute;
        border: 4px solid #fff;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
      }

}

.carousel.secondary-big {
    overflow: hidden;
    margin: 0px auto 0;
    display: flex;
    width: 100%;
    align-items: center;    
    justify-content: center;

    @include media-tablet-down {
        margin: 0 auto;
    }

    .carousel-dots {
        display: none;
    }

    .prev-arrow, .next-arrow {
        top: calc(50% + 50px);
        @include media-tablet-down() {
            top: 50%;
        }
    }


    @include media-tablet-down {
        .prev-arrow {
            left: -12px;
        }
    
        .next-arrow {
            right: -12px;
        }
    }

    .carousel-sections {
        height: auto;

        .carousel-section {
            width: 1240px;
            padding-top: 61%;
            position: relative;

            .content {
                position: absolute;
                top: 130px;
                left: 80px;
                width: calc(100% - 160px);
                height: calc(100% - 160px);
                background-position: center top;
                background-size: cover;
                @include media-tablet-down {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                iframe {
                    background: transparent;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                h2 {
                    position: absolute;
                    top: -87px;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 40px;
                    transition: .2s ease-in;
                    text-transform: uppercase;
                    color: $highlight;
                    font-size: 36px;
                    font-weight: bold;
                    text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000, 3px 3px 0px #000000;
                }
            }
        }
    }
}

.carousel.secondary-thumbnails {
    margin-top: 14px;
    padding-bottom: 50px;

    @include media-desktop-up {
        max-width: 1420px;
        margin-left: auto;
        margin-right: auto;
    }
    @include media-tablet-down {
        display: none;
    }

    .prev-arrow {
        displaY: none;
    }

    .next-arrow {
        display: none;
    }

    .carousel-dots {
        display: none;
    }

    .carousel-sections {
        height: auto; 
    }

    .carousel-section {
        min-width: 8%;
        max-width: 20vw;
        width: 190px;
        height: 125px;
        position: relative;
        border-radius: 0;
        padding: 0 10px;

        &.active .content {
            box-shadow: 0 0 0 5px rgba($highlight, 1);
        }

        .content {
            position: absolute;
            top: 10px;
            left: 10px;
            bottom: 10px;
            right: 10px;
            background: $white;
            transition: .3s ease-in;
            box-shadow: 0 0 0 0 rgba($highlight, 0);
            button {
                width: 100%;
                height: 100%;
                display: block;
                background-size: cover;
                border: 0;
                background-position: center;
            }
        }
    }
    
}