.section.about-the-game {
    display: block;
    @include  backgroundImage('images/bg-black-circles.webp');
    background-size: cover;
    background-attachment: fixed;
    background-position: center right;
    background-color: $black;

    @include media-phone-only() {
        background-attachment: scroll;
    }

    h1 {
        background-color: #0f101a;
    }

    .background-line {
        width: 100%;
        background-color: brown;
        &.brown{@include backgroundImage('images/bg-brown.webp')}
        &.red{@include backgroundImage('images/bg-dark-red.webp')}
    }

    .about-block {
        padding: 30px 0;
        width: 1120px;
        max-width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        box-sizing: border-box;
        
        @include media-tablet-down() {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .image {
            width: 50%;
            padding: 0 25px;
            @include media-tablet-down() {
                width: 100%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
        
        .content {
            width: 50%;
            padding: 0 25px;
            @include media-tablet-down() {
                width: 100%;
            }
            h3 {
                font-size: 18px;
                line-height: 120%;
                color: $highlight;
                text-transform: uppercase;
                font-weight: bold;
                margin-bottom: 1em;
                @include media-tablet-down() {
                    margin-top: 1em;
                }

                &:not(:first-child) {
                    margin-top: 5em;
                    @include media-tablet-down() {
                        margin-top: 2em;
                    }
                }
            }

            p {
                font-size: 18px;
                line-height: 120%;
                margin-bottom: 1em;
            }
        }
        

        &.reversed {
            flex-direction: row-reverse;


        }

    }
}