.section.key-features { 
    display: block;ű
    
    h1 { 
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #80231f;
        color: $highlight;
        text-transform: uppercase;
        font-size: 36px;
        font-weight: bold;
        text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000, 3px 3px 0px #000000;
    }


	.wrapper { 
        padding-top: 55px;
        padding-bottom: 75px;

		.feature-list { 
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
			.feature { 
                box-sizing: border-box;
                width: 20%;
                text-align: center;
                padding: 20px;
                @include media-desktop-down() {
                    width: 33.333%;
                }
                @include media-tablet-down() {
                    width: 50%;
                }
                @include media-phone-only() {
                    width: 100%;
                }

				img { 
                    width: 110px;
                    height: auto;
                    max-width: 100%;
                    margin-bottom: 24px;
				}

				p { 
                    font-size: 18px;
                    line-height: 120%;
				}
			}
		}

		h5 { 
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            text-transform: uppercase;
            color: $highlight;
            text-shadow: 1px 1px 0px #000000, 2px 2px 0px #000000;
            font-weight: bold;
            font-size: 21px;
            margin-top: 45px;
		}
	}
}