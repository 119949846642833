.section.header {
    margin-bottom: 75px;
    max-width: 100vw;
    overflow: hidden;
    position: relative;
    z-index: 1000;

    @include media-tablet-down {
        margin-bottom: 45px;
    }
}

.section.header > .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    background-color: $black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    z-index: 1700;
    
    nav {
        max-width: 1420px;        
        width: calc(100% - 30px);
        margin: 0 auto;
        padding: 0;
        color: $white;

        
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 75px;

        @include media-tablet-down {
            justify-content: space-between;
            height: 45px;
        }
    }
    
    .logo {
        display: block;
        margin-right: 23px;
        background-color: transparent;
        border: 0;
        img {
            width: auto;
            height: 50px;
            transition: .5s ease all;

            &:hover {
                transform: scale(1.1);
            }
        }

        @include media-tablet-down {
            margin-left: 10px;

            img {
                height: 35px;
                transition: none;
            }
        }

        @include media-phone-only {
            margin-left: -10px;
        }
    }

    .navlink {
        font-size: 14px;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
        display: block;
        margin: 0px 23px;
        cursor: pointer;
        position: relative;
        transition: .5s ease;
        background-color: transparent;
        border: 0;
        text-align: left;

        @include media-desktop-down {
            margin: 0 10px;
        }
        @include media-tablet-down {
            display: none;
        }

        &::after {
            display: block;
            height: 3px;
            width: 0;
            position: absolute;
            left: 50%;
            bottom: -8px;
            content: " ";
            background: $white;
            transform: translateX(-50%);
            transition: .5s ease;
            @include media-tablet-down {
                display: none;
            }
        }


        &:hover, &.active {
            color: $highlight;

            &::after {
                width: 100%;
                background-color: $highlight;
            }
        }


    }

    .socials {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        @include media-tablet-down {
            display: none;
            width: 100%;
        }
        .social {
            display: block;
            width: 23px;
            height: 23px;
            margin-left: 7px;
            background-size: contain;
            cursor: pointer;
            transition: .5s ease;
            border-radius: 50%;
            transition: .5s ease;

            &.steam {@include backgroundImage('/images/social-steam.png')}
            &.instagram {@include backgroundImage('/images/social-instagram.png')}
            &.x {@include backgroundImage('/images/social-x.png')}
            &.youtube {@include backgroundImage('/images/social-youtube.png')}
            &.facebook {@include backgroundImage('/images/social-facebook.png')}
            &.discord {@include backgroundImage('/images/social-discord.png')}

            &:hover {
                box-shadow: 0 0 0 2px $darkyellow;
                background-color: $darkyellow;
                filter:  contrast(3) brightness(1.4);
            }


        }
    }

    .hamburger-button {
        @include backgroundImage('/images/hamburger-icon.png');
        width: 35px;
        height: 35px;
        border: 0;
        background-size: contain;
        background-color: transparent;
        background-position: center;
        margin-right: 10px;
        padding: 0;
        cursor: pointer;
        display: none;
        position: absolute;
        top: 4px;
        right: 0;
        @include media-tablet-down {
            display: block;
        }
    }
}

.section.header.opened > .wrapper {
    @include media-tablet-down {
        height: auto;
        

        nav {
            display: block;
            height: auto;
            
        }

        .logo {
            width: 100%;
            margin-bottom: 10px;
            margin-top: 10px;
            img {
                height: 60px;

            }
        }

        .navlink {
            width: 100%;
            display: block;
            padding: 20px 10px;
            font-size: 20px;
            border-top: 1px solid rgba($white, 0.2);
            margin: 0;
        }

        .socials {
            display: flex;
            padding-bottom: 10px;
            flex-wrap: wrap;
            margin: 20px 10px 0;
            width: calc(100% - 20px);
            .social {
                width: 35px;
                height: 35px;
                margin: 8px;
            }
        }
    }
}