.section.home-about {
    @include backgroundImage("images/home-about-bg.jpg");
    background-color: $black;
    background-size: contain;
    background-position: center;
    height: 670px;
    margin-top: 60px;
    align-items: flex-start;
    z-index: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    
    @include media-tablet-down() {
        margin-top: 0;
    }

    @include media-desktop-down() {
        background-position: right center;
        height: auto;
    }

    @include media-tablet-down() {
        background-size: cover;
        background-position: 30%;
    }

    .wrapper {
        padding: 70px;
        @include media-desktop-down() {
            padding: 40px;
        }
        .content {
            padding: 0 40px;
            width: 640px;
            max-width: 60vw;
            @include media-desktop-down() {
                padding: 20px;
            }
            @include media-tablet-down() {
                padding: 0;
                max-width: 100%;
            }

            h3 {
                color: $highlight;
                font-size: 22px;
                margin-bottom: 1.3em;
                text-shadow: 1px 1px 0px $black;
            }

            p {
                font-size: 22px;
                margin-bottom: 1.3em;
                text-shadow: 1px 1px 0px $black;
                line-height: 120%;

                .link {
                    color: $highlight;
                    text-shadow: 3px 3px 0px $black;
                    padding: 5px;
                    display: inline-block;   
                }
            }
            @include media-desktop-down() { 
                p {
                    font-size: 15px;

                    .link {
                        font-size: 21px;
                    }
                }
            }
        }
    }
}